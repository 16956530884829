<template>
  <v-container id="productos" tag="section">
    <base-material-card
      class="px-5 py-3"
      color="primary"
      icon="mdi-food"
      inline
      :title="$t('products')"
    >
      <v-spacer></v-spacer>
      <template v-slot:after-heading></template>

      <!-- Boton nuevo registro -->
      <template v-slot:corner-button>
        <v-btn class="ma-2" color="primary" elevation="2" small @click.stop="dialog = true"
          >Nuevo Producto</v-btn
        >
      </template>

      <!-- Overlay para retroalimentar al usuario -->
      <v-overlay :value="overlay">
        <v-progress-circular color="success" indeterminate :size="50"></v-progress-circular>
      </v-overlay>

      <!-- Textfield para la barra de Búsqueda -->
      <v-text-field
        append-icon="mdi-magnify"
        class="ml-auto"
        hide-details
        label="Búsqueda"
        single-line
        style="max-width: 250px"
        v-model="search"
      />

      <!-- Dialogo cancelar -->
      <ConfirmationDialog
        buttonColor1="red"
        buttonText1="NO"
        buttonColor2="primary"
        buttonText2="SI"
        text="Seguro quiere cancelar?"
        title="¡Se perderan todos los cambios!"
        v-model="isSure"
        :width="420"
        @click="cancel"
      />

      <!-- Dialogo Nuevo Agregar/Editar Producto -->
      <v-dialog v-model="dialog" max-width="500px" persistent>
        <v-card>
          <!-- <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title> -->
          <v-card-text>
            <base-subheading :subheading="formTitle" />
            <v-container>
              <v-row>
                <!-- FOTO -->
                <v-col cols="12">
                  <center>
                    <!-- <v-row align="end"> -->
                    <div>
                      <ThumborImage
                        v-if="editedItem.imagen"
                        :avatarSize="100"
                        :imageUrl="editedItem.imagen"
                      />
                      <ThumborImage v-else :avatarSize="100" :imageUrl="editedItem.imagen" />
                    </div>
                    <div>
                      <v-btn
                        block
                        class="text-none"
                        color="primary"
                        depressed
                        elevation="5"
                        rounded
                        small
                        :loading="isSelecting"
                        @click="onButtonClick"
                      >
                        <v-icon v-if="selectedFile === null" left> mdi-cloud-upload </v-icon>
                        <v-icon v-else left> mdi-cloud-check </v-icon>
                        {{ buttonText }}
                      </v-btn>
                      <input
                        accept="image/*"
                        class="d-none"
                        ref="uploader"
                        type="file"
                        @change="onFileChanged"
                      />
                    </div>
                    <!-- </v-row> -->
                  </center>
                </v-col>

                <!-- Producto -->
                <v-col cols="6">
                  <v-text-field
                    dense
                    label="Producto*"
                    maxlength="20"
                    required
                    v-model="editedItem.producto"
                    :error-messages="productoErrors"
                    @blur="$v.editedItem.producto.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.producto.$reset()"
                  ></v-text-field>
                </v-col>

                <!-- Descripción -->
                <v-col cols="6">
                  <v-text-field
                    dense
                    counter="50"
                    label="Descripción*"
                    maxlength="50"
                    required
                    v-model="editedItem.descripcion"
                    :error-messages="descripcionErrors"
                    @blur="$v.editedItem.descripcion.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.descripcion.$reset()"
                  ></v-text-field>
                </v-col>

                <!-- Precio -->
                <v-col cols="6">
                  <v-text-field
                    dense
                    hint="Introducir precio del producto"
                    label="Precio*"
                    maxlength="20"
                    prefix="$"
                    required
                    v-model="editedItem.precio"
                    :error-messages="precioErrors"
                    @blur="$v.editedItem.precio.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.precio.$reset()"
                    @keypress="isNumber($event)"
                  ></v-text-field>
                </v-col>

                <!-- Categoría -->
                <v-col cols="6">
                  <v-select
                    dense
                    hint="Seleciona Categoría"
                    item-text="categoria"
                    item-value="_id"
                    label="Categoría*"
                    persistent-hint
                    return-object
                    required
                    v-model="editedItem.categoria"
                    :items="productCategorys"
                    :menu-props="{ top: true, offsetY: true }"
                  ></v-select>
                </v-col>

                <!-- Minimo -->
                <v-col cols="6">
                  <v-text-field
                    dense
                    hint="Introducir existencias mínimas del producto"
                    label="Existencia Mín.*"
                    maxlength="3"
                    required
                    v-model="editedItem.minimo"
                    :error-messages="minimoErrors"
                    @blur="$v.editedItem.minimo.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.minimo.$reset()"
                    @keypress="isNumber($event)"
                  ></v-text-field>
                </v-col>

                <!-- Maximo -->
                <v-col cols="6">
                  <v-text-field
                    dense
                    hint="Introducir existencias máximas del producto"
                    label="Existencia Máx.*"
                    maxlength="3"
                    required
                    v-model="editedItem.maximo"
                    :error-messages="maximoErrors"
                    @blur="$v.editedItem.maximo.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.maximo.$reset()"
                    @keypress="isNumber($event)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <small>*Campo Obligatorio</small>
          </v-card-text>

          <!-- Botones y Crear Otro -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-checkbox
              label="Crear otro"
              v-if="editedIndex === -1"
              v-model="checkbox"
              :disabled="isEditing"
            ></v-checkbox>

            <!-- Botones de guardar y cancelar -->
            <v-btn color="error" text @click="checkChanges">Cancelar</v-btn>
            <v-btn color="primary" :disabled="$v.$invalid" text @click="save">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Termina Dialogo -->

      <v-divider class="mt-3" />

      <!-- Tabla de datos de Productos -->
      <v-data-table
        dense
        loading-text="Cargando... Espere por favor"
        :headers="headers"
        :items="productos"
        :loading="isLoading"
        :search.sync="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="mr-2" small @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>

        <!-- Creo el boton de Activo-->
        <template v-slot:[`item.activo`]="{ item }">
          <Status :activo="item.activo" />
        </template>

        <!-- Mostrar Imagen del Producto -->
        <template v-slot:[`item.imagen`]="{ item }">
          <!-- <img :src="item.imagen" alt="" width="50" height="50" /> -->
          <ThumborImage
            v-if="item.imagen"
            tipoImagen="foto"
            :avatarSize="45"
            :imageUrl="item.imagen"
          />
          <ThumborImage v-else tipoImagen="imagen" :avatarSize="45" />
        </template>

        <template v-slot:no-data>
          <!-- <v-btn color="primary">Reset</v-btn> -->
          ¡No hay datos para mostrar!
        </template>
      </v-data-table>

      <!-- DataExporter -->
      <DataExporter :dataArray="productos" :dataFields="fields" fileName="Productos" />
    </base-material-card>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

import ConfirmationDialog from '@/components/general/ConfirmationDialog.vue'
import DataExporter from '@/components/general/DataExporter.vue'
import Status from '@/components/general/StatusInfo.vue'
import ThumborImage from '@/components/general/ThumborImage.vue'

export default {
  components: {
    ConfirmationDialog,
    DataExporter,
    Status,
    ThumborImage,
  },
  data: () => ({
    changed: false,
    checkbox: false,
    defaultButtonText: 'Cargar imagen del producto*',
    dialog: false,
    editedIndex: -1,
    isEditing: false,
    isLoading: false,
    isSelecting: false,
    isSure: false,
    overlay: false,
    search: undefined,
    selectedFile: null,
    headers: [
      { text: 'Imagen', value: 'imagen', sortable: false },
      { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
      { text: 'Descripción', sortable: true, value: 'descripcion' },
      { text: 'Precio', sortable: true, value: 'precio' },
      { text: 'Categoría', sortable: true, value: 'categoria.categoria' },
      { text: 'Mínimo', sortable: false, value: 'minimo' },
      { text: 'Máximo', sortable: false, value: 'maximo' },
      { text: 'Activo', value: 'activo' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    productos: [],
    productCategorys: [],
    defaultItem: {
      _id: '',
      activo: true,
      createdAt: '',
      createdBy: '',
      descripcion: '',
      imagen: '',
      precio: '',
      productos: '',
      minimo: 1,
      maximo: 999,
      updatedAt: '',
      categoria: { _id: '', categoria: '' },
    },
    editedItem: {
      _id: '',
      activo: true,
      createdAt: '',
      createdBy: '',
      descripcion: '',
      imagen: '',
      precio: '',
      productos: '',
      minimo: 1,
      maximo: 999,
      updatedAt: '',
      categoria: { _id: '', categoria: '' },
    },
    fields: {
      Producto: 'producto',
      Descripción: 'descripcion',
      Precio: 'precio',
      Categoria: 'categoria.categoria',
      Minimo: 'minimo',
      Maximo: 'maximo',
    },
  }),
  mixins: [validationMixin],
  //Validaciones para los campos del formulario
  validations: {
    editedItem: {
      producto: { required, minLength: minLength(3), maxLength: maxLength(20) },
      descripcion: { required, minLength: minLength(3), maxLength: maxLength(50) },
      precio: { required, minLength: minLength(1), maxLength: maxLength(20) },
      categoria: {
        categoria: { required },
      },
      minimo: { required, minLength: minLength(1), maxLength: maxLength(3) },
      maximo: { required, minLength: minLength(1), maxLength: maxLength(3) },
    },
  },
  computed: {
    buttonText() {
      return this.selectedFile ? this.selectedFile.name : this.defaultButtonText
    },

    descripcionErrors() {
      const errors = []
      if (!this.$v.editedItem.descripcion.$dirty) return errors
      !this.$v.editedItem.descripcion.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.descripcion.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.descripcion.maxLength &&
        errors.push('Este campo debe de tener un máximo de 50 caracteres.')
      return errors
    },

    //Titulo del Módulo si es Nuevo o Editar
    formTitle() {
      return this.editedIndex === -1 ? 'Nuevo Producto' : 'Editar Producto'
    },

    maximoErrors() {
      const errors = []
      if (!this.$v.editedItem.maximo.$dirty) return errors
      !this.$v.editedItem.maximo.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.maximo.minLength &&
        errors.push('Este campo debe de tener un mínimo de 1 digitos.')
      !this.$v.editedItem.maximo.maxLength &&
        errors.push('Este campo debe de tener un máximo de 3 digitos.')
      return errors
    },

    minimoErrors() {
      const errors = []
      if (!this.$v.editedItem.minimo.$dirty) return errors
      !this.$v.editedItem.minimo.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.minimo.minLength &&
        errors.push('Este campo debe de tener un mínimo de 1 digitos.')
      !this.$v.editedItem.minimo.maxLength &&
        errors.push('Este campo debe de tener un máximo de 3 digitos.')
      return errors
    },

    precioErrors() {
      const errors = []
      if (!this.$v.editedItem.precio.$dirty) return errors
      !this.$v.editedItem.precio.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.precio.minLength &&
        errors.push('Este campo debe de tener un mínimo de 2 caracteres.')
      !this.$v.editedItem.precio.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },

    productoErrors() {
      const errors = []
      if (!this.$v.editedItem.producto.$dirty) return errors
      !this.$v.editedItem.producto.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.producto.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.producto.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
  },
  created() {
    this.obtenerProductos()
    this.obtenerCategoriasProductos()
  },
  methods: {
    /**
     * @description Borrar en BD no funciona por el momento
     */
    async BorrarRegistro() {
      this.overlay = true
      this.$v.$touch()

      let body = {
        _id: this.editedItem._id,
      }
      let payload = { body: body }
      payload.id = this.editedItem._id
      this.$store
        .dispatch('product/deleteProduct', payload)
        .then(() => {
          this.$store.commit('ALERT', {
            color: 'success',
            text: 'El registro se eliminó con éxito',
          })
          this.overlay = false
        })
        .catch((err) => {
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.msg,
            // Enviar a inicio de sesión
          })
          this.overlay = false
        })
      this.editedItem = Object.assign({}, this.defaultItem)
    },

    /**
     * @description Verifica si esta seguro de salir del dialogo
     */
    cancel(isSure) {
      this.isSure = isSure
      if (this.isSure) {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Verifica si cambio algun campo
     */
    checkChanges() {
      if (this.changed) {
        this.isSure = true
      } else {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Cierra el dialogo
     * @param {bool} checkbox boleano que indica el estado del checkbox
     */
    close(checkbox) {
      this.isSure = false
      this.changed = false
      this.checkbox = checkbox
      this.isEditing = false
      if (!this.checkbox) {
        this.dialog = false
      }
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.selectedFile = null

      this.$v.$reset()
    },

    /**
     * @description Elimina un producto
     * @param {object} item elemento seleccionado
     */
    deleteItem(item) {
      const index = this.productos.indexOf(item)
      this.editedItem = Object.assign({}, item)
      //Confirma cerrar formulario
      if (confirm('Esta seguro que desea eliminar este registro?')) {
        this.BorrarRegistro()
        this.productos.splice(index, 1)
      }
    },

    /**
     * @description Obtiene el indice del elemento seleccionado y lo signa al array editedItem
     * @param {object} item elemento seleccionado
     */
    editItem(item) {
      this.isEditing = true
      this.editedIndex = this.productos.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    /**
     * @description Evalua el caracter tecleado para saber si es un numero
     */
    isNumber: function (evt, negativo = false) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode

      if (negativo && charCode === 45) {
        return true
      }

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46 //&&
        // charCode !== 45
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },

    /**
     * @description Obtiene las categorías de productos
     * @return {array} arreglo con todos los productos
     */
    async obtenerCategoriasProductos() {
      this.isLoading = true
      let payload = {}
      this.$store
        .dispatch('productCategory/fetchProductCategory', payload)
        .then((response) => {
          this.productCategorys = response.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
            // Enviar a inicio de sesión
          })
        })
      // }
    },

    /**
     * @description Obtiene los productos
     * @return {array} arreglo con todaos los productos
     */
    async obtenerProductos() {
      this.isLoading = true
      let payload = {}
      this.$store
        .dispatch('product/fetchProduct', payload)
        .then((response) => {
          this.productos = response.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
            // Enviar a inicio de sesión
          })
        })
    },

    /**
     * @description Verifica que se haya cargado la imagen al servidor
     * desde que se hace click en el botón
     */
    onButtonClick() {
      this.isSelecting = true
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false
        },
        { once: true }
      )

      this.$refs.uploader.click()
    },

    /**
     * @description Verifica si se cargo un archivo
     * y llama al método para cargarlo al servidor
     */
    onFileChanged(e) {
      this.selectedFile = e.target.files[0]
      this.uploadFile()
    },

    /**
     * @description Marca que ha cambiado el valor de un campo
     */
    onTextChange() {
      this.changed = true
    },

    /**
     * @description Guarda el contenido del dialogo ya sea para un producto nuevo o
     * la edicion de un producto
     */
    async save() {
      this.overlay = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.editedItem.imagenAnterior !== '') {
          console.log('Borrar foto anterior')
        }
        let body = {
          producto: this.editedItem.producto,
          descripcion: this.editedItem.descripcion,
          precio: this.editedItem.precio,
          imagen: this.editedItem.imagen,
          categoria: this.editedItem.categoria._id,
          minimo: this.editedItem.minimo,
          maximo: this.editedItem.maximo,
          activo: this.editedItem.activo,
        }
        let payload = { body: body }
        if (this.editedIndex > -1) {
          payload.id = this.editedItem._id
          this.$store
            .dispatch('product/editProduct', payload)
            .then(() => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se actualizo con éxito',
              })
              this.overlay = false
              this.close(this.checkbox)
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.errores[0].msg,
                // Enviar a inicio de sesión
              })
              this.overlay = false
            })
          Object.assign(this.productos[this.editedIndex], this.editedItem)
        } else {
          this.$store
            .dispatch('product/addProduct', payload)
            .then((response) => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se creó con éxito',
              })
              this.productos.push(response.data)
              this.overlay = false
              this.close(this.checkbox)
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.errores[0].msg,
                // Enviar a inicio de sesión
              })
              this.overlay = false
            })
        }
        // this.close(this.checkbox)
      }
    },

    //MANEJO DE ARCHIVOS
    /**
     * @description Carga un archivo al servidor
     */
    async uploadFile() {
      if (this.selectedFile != '') {
        let fd = new FormData()
        fd.append('attachment', this.selectedFile)
        let payload = { body: fd }
        this.$store
          .dispatch('product/addImageProduct', payload)
          .then((response) => {
            // this.$store.commit('ALERT', {
            //   color: 'success',
            //   text: 'La imagen fue cargada con éxito',
            // })
            // this.editedItem.imagen = URL.createObjectURL(this.selectedFile)
            // this.editedItem.imagenAnterior = this.editedItem.imagen
            this.editedItem.imagen = response.data.url
            this.$refs.uploader.value = ''
          })
          .catch((err) => {
            this.$store.commit('ALERT', {
              color: 'error',
              text: err.response.data.msg,
            })
          })
      } else {
        this.$store.commit('ALERT', {
          color: 'info',
          text: 'Seleccione una imagen',
        })
      }
    },
  },
}
</script>

<style></style>
